import { global } from ":global";
import type { ApiResponse } from "./_model";

export async function getTeams(): Promise<ApiResponse.GetTeams> {
  // return Promise.resolve(
  //   import("./get-teams.mocks").then((e) => {
  //     return Promise.resolve(e.success);
  //   })
  // );

  return global.api.getAuth("teaming/user-requests/").then((res) => {
    return res.data;
  });
}
